import "./style.css";

export default class Header {
  static selector = ".header";

  constructor(block) {
    this.block = block;
  }

  onReady = () => {
    return new Promise(async (resolve, reject) => {
      this.mounted = true;

      resolve();
    });
  };

  onComplete = () => {
    return new Promise(async (resolve, reject) => {
      this.onResize();

      resolve();
    });
  };

  onResize = () => {};
}
