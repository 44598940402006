import { getRaf } from "@app";
import { clamp } from "@utils/math";

import "./style.css";

export default class Carousel {
  static name = "Carousel";

  constructor(block) {
    this.block = block;
    this.itemNodes = this.block.querySelectorAll(".carousel__item");
    this.itemsNode = this.block.querySelector(".carousel__items");

    this.index = 0;
    this.animations = {
      itemsNode: {
        node: this.itemsNode,
        styles: {
          translateX: {
            dampingFactor: 0.09,
            deltaX: 0,
            deltaX0: 0,
            fromValue: 0,
            setValue: () => {
              this.animations.itemsNode.styles.translateX.deltaX0 =
                this.animations.itemsNode.styles.translateX.deltaX0 +
                (this.animations.itemsNode.styles.translateX.deltaX -
                  this.animations.itemsNode.styles.translateX.deltaX0) *
                  this.animations.itemsNode.styles.translateX.dampingFactor;

              return this.animations.itemsNode.styles.translateX.deltaX0;
            },
          },
        },
      },
    };

    this.block.addEventListener("touchstart", this.onTouchStart, {
      passive: true,
    });
    this.block.addEventListener("touchmove", this.onTouchMove, {
      passive: true,
    });
    this.block.addEventListener("touchend", this.onTouchEnd, {
      passive: true,
    });
    this.block.addEventListener("mousedown", this.onTouchStart);
    this.block.addEventListener("mousemove", this.onTouchMove);
    this.block.addEventListener("mouseup", this.onTouchEnd);
    this.block.addEventListener("mouseleave", this.onMouseOut);

    this.setItemsRect();
    this.setItemsNodeMargin();
    this.onResize();
  }

  setItemsRect = () => {
    if (!this.itemNodes || this.itemNodes.length < 2) {
      return null;
    }

    this.itemNodes[0].rect = this.itemNodes[0].getBoundingClientRect();
    this.itemNodes[1].rect = this.itemNodes[1].getBoundingClientRect();

    this.itemMarginRight = this.getItemMarginRight();
    this.itemWidth = this.itemNodes[0].rect.width + this.itemMarginRight;
  };

  getItemMarginRight = () => {
    if (!this.itemNodes || this.itemNodes.length < 2) {
      return null;
    }

    return (
      this.itemNodes[1].rect.left -
      (this.itemNodes[0].rect.width + this.itemNodes[0].rect.left)
    );
  };

  setItemsNodeMargin = () => {
    if (!this.itemsNode) {
      return null;
    }

    this.itemsNodeMargin =
      this.itemsNode.offsetWidth -
      (this.itemWidth * this.itemNodes.length - this.itemMarginRight);
  };

  onTouchStart = (e) => {
    this.block.style.cursor = "grabbing";

    this.animations.itemsNode.styles.translateX.x0 =
      (e.touches && e.touches[0] && e.touches[0].clientX) || e.clientX;
  };

  onTouchMove = (e) => {
    if (!this.animations.itemsNode.styles.translateX.x0) {
      return;
    }

    this.animations.itemsNode.styles.translateX.x =
      (e.touches && e.touches[0] && e.touches[0].clientX) || e.clientX;

    this.animations.itemsNode.styles.translateX.dX =
      this.animations.itemsNode.styles.translateX.x -
      this.animations.itemsNode.styles.translateX.x0;

    this.animations.itemsNode.styles.translateX.deltaX =
      this.animations.itemsNode.styles.translateX.x -
      this.animations.itemsNode.styles.translateX.x0 +
      this.animations.itemsNode.styles.translateX.fromValue;
  };

  onTouchEnd = (e) => {
    this.block.style.cursor = null;

    if (
      !this.animations.itemsNode.styles.translateX.x0 ||
      !this.animations.itemsNode.styles.translateX.x
    ) {
      this.animations.itemsNode.styles.translateX.x = null;
      this.animations.itemsNode.styles.translateX.x0 = null;

      return;
    }

    this.updateIndex();

    this.animations.itemsNode.styles.translateX.x = null;
    this.animations.itemsNode.styles.translateX.x0 = null;
  };

  onMouseOut = () => {
    this.block.style.cursor = null;

    if (!this.animations.itemsNode.styles.translateX.x0) {
      return;
    }

    this.updateIndex();

    this.animations.itemsNode.styles.translateX.x0 = null;
    this.animations.itemsNode.styles.translateX.x = null;
  };

  updateIndex = () => {
    if (
      Math.abs(this.animations.itemsNode.styles.translateX.dX) >
      this.itemWidth / 4
    ) {
      this.index =
        this.animations.itemsNode.styles.translateX.dX < 0
          ? this.index - 1
          : this.index + 1;

      const itemsInView = Math.trunc(this.block.offsetWidth / this.itemWidth);
      this.index = clamp(this.index, -(this.itemNodes.length - itemsInView), 0);
    }

    this.animations.itemsNode.styles.translateX.deltaX =
      this.animations.itemsNode.styles.translateX.fromValue =
        this.index * this.itemWidth;
    this.animations.itemsNode.styles.translateX.dX = 0;
  };

  render = () => {
    for (const key in this.animations.itemsNode.styles) {
      this.animations.itemsNode.styles[key].current =
        this.animations.itemsNode.styles[key].setValue();
    }

    this.layout();
  };

  layout = () => {
    this.itemsNode.style.transform = `translate3d(${this.animations.itemsNode.styles.translateX.current}px, 0, 0)`;
  };

  onResize = () => {
    this.setItemsRect();
    this.setItemsNodeMargin();
    this.updateIndex();

    if (!this.raf) {
      this.raf = getRaf();
      this.raf.register(this.block.dataset.instanceIndex, this.render);
    }
  };
}
