import "./style.css";
import { getScroll } from "@app";

export default class Modal {
  static selector = ".modal";

  constructor(block) {
    this.block = block;
  }

  show = () => {
    this.scroll.stop();
    this.block.classList.add("modal--open");
  };

  hide = () => {
    this.scroll.start();
    this.block.classList.remove("modal--open");
  };

  handleCloseButtonClick = () => {
    this.hide();
  };

  onReady = async () => {
    this.mounted = true;
    this.scroll = getScroll();

    this.closeButton = this.block.querySelector(".modal__close");
    this.closeButton?.addEventListener("click", this.handleCloseButtonClick);
  };
  onResize = () => {};
}
