import "./style.css";
import CustomImage from "@blocks/Image";
import { getInstance } from "@app";

export default class Hero {
  static selector = ".hero";

  constructor(block) {
    this.block = block;
    this.imageNode = block.querySelector(".hero__background");

    this.playButton = block.querySelector(".hero__play");
    this.playButton.addEventListener("click", this.onPlayButtonClicked);
  }

  onPlayButtonClicked = () => {
    getInstance(document.querySelector(".promo-video-modal")).show();
  };

  onReady = () => {
    return new Promise(async (resolve, reject) => {
      this.mounted = true;

      await new CustomImage(this.imageNode, {
        src: "https://i.imgur.com/pCt7E5U.jpg",
        alt: "",
      }).onReady();
      resolve();
    });
  };

  onComplete = () => {};

  onResize = () => {};
}
