import "./style.css";
import CustomImage from "@blocks/Image";
import { getScroll } from "@app";
import { clamp, map } from "@utils/math";

export default class Essiccatura {
  static selector = ".essiccatura";

  constructor(block) {
    this.block = block;
    this.imageNode = block.querySelector(".essiccatura__image .image");
  }

  onScrollPositionChange = (event) => {
    const { animatedScroll } = event;
    const { height } = this.bounds;
    const { height: wHeight } = this.windowSizes;
    const y = animatedScroll + wHeight;

    const progress = clamp(
      map(y, this.top, this.top + height + wHeight, -1, 1),
      -1,
      1
    );

    //this.imageDecoratorNode.style.transform = `rotate(${180 * progress}deg)`;
  };

  setupImage = () => {
    return new Promise(async (resolve) => {
      await new CustomImage(this.imageNode, {
        src: "https://i.imgur.com/35g1SFd.jpg",
        alt: "",
      }).onReady();

      resolve();
    });
  };

  onReady = () => {
    return new Promise(async (resolve, reject) => {
      this.mounted = true;

      await this.setupImage();

      resolve();
    });
  };

  onComplete = () => {
    this.onResize();

    this.scroll = getScroll();
    this.scroll.registerOnScrollPositionChange(this.onScrollPositionChange);
  };

  onResize = () => {
    this.bounds = this.block.getBoundingClientRect();
    this.top = this.block.offsetTop;
    this.windowSizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  };
}
