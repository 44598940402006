import "./style.css";
import { getScroll } from "@app";

export default class AgeVerification {
  static selector = ".age-verification";

  constructor(block) {
    this.block = block;

    this.actionNodes = block.querySelectorAll(
      ".age-verification__buttons__button"
    );
  }

  show = () => {
    this.scroll?.stop();
    this.block.classList.add("age-verification--visible");
  };

  hide = () => {
    this.scroll?.start();
    this.block.classList.remove("age-verification--visible");
  };

  getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  };

  onActionButtonClicked = (e) => {
    const answer = e.currentTarget.dataset.ageVerification;
    const value = answer === "yes" ? true : false;

    document.cookie = `age-verification--viridian=${value}; expires=${new Date(
      new Date().getTime() + 1000 * 60 * 60 * 24 * 365
    ).toUTCString()}`;

    this.hide();
  };

  onLoaderHidden = () => {
    const cookie = this.getCookie("age-verification--viridian");

    if (cookie === undefined || cookie !== "true") {
      this.show();

      this.actionNodes.forEach((node) => {
        node.addEventListener("click", this.onActionButtonClicked);
      });
    }
  };

  onPageChangeComplete = () => {
    this.onLoaderHidden();
  };

  onReady = () => {
    return new Promise(async (resolve, reject) => {
      this.mounted = true;

      this.scroll = getScroll();

      resolve();
    });
  };
}
