import "./style.css";

export default class Bimbo {
  static selector = ".bimbo";

  constructor(block) {
    this.block = block;
  }

  onReady = () => {};
}
