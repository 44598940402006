import "./style.css";
import CustomImage from "@blocks/Image";
import { getScroll } from "@app";
import { clamp, map } from "@utils/math";
import Carousel from "@components/Carousel";

export default class Serve {
  static selector = ".serve";

  constructor(block) {
    this.block = block;
    this.itemsNode = block.querySelector(".serve__items");
    this.itemTemplate = block.querySelector("#serve-item-template");
    this.decoratorNode = block.querySelector(
      ".serve__items-wrapper__decorator"
    );
    this.carouselNode = block.querySelector(".serve__items-carousel.carousel");

    this.items = [
      {
        image: {
          title: "liscio",
          image: {
            src: "https://i.imgur.com/T4IeJhh.jpg",
            alt: "",
          },
        },

        text: "Avresti mai detto di riuscire a bere un gin liscio? Viridian è un perfetto sostituto di una qualsiasi grappa o amaro grazie alla sua profondità aromatica che invita alla contemplazione.",
      },

      {
        image: {
          title: "tonica",
          small: true,
          image: {
            src: "https://i.imgur.com/ZVw42Jb.jpg",
            alt: "",
          },
        },

        text: "Uno dei cocktail più bevuti al mondo. L’effervescenza e la freschezza dell’acqua tonica evidenziano le note aromatiche di Viridian.",
      },

      {
        image: {
          title: "classici",
          small: true,
          image: {
            src: "https://i.imgur.com/IvKNukB.jpg",
            alt: "",
          },
        },

        text: "Molto versatile negli accostamenti, Viridian si adatta ai grandi classici della mixology, grazie al profilo aromatico che può integrarsi armoniosamente con gli altri ingredienti di un cocktail.",
      },
    ];
  }

  onScrollPositionChange = (event) => {
    const { animatedScroll } = event;
    const { height } = this.bounds;
    const { height: wHeight } = this.windowSizes;
    const y = animatedScroll + wHeight;

    const progress = clamp(
      map(y, this.top, this.top + height + wHeight, -1, 1),
      -1,
      1
    );

    this.decoratorNode.style.transform = `rotate(${180 * progress}deg)`;
  };

  setupItems = () => {
    return new Promise(async (resolve) => {
      await Promise.all(
        this.items.map(async (item) => {
          const itemNode =
            this.itemTemplate.content.firstElementChild.cloneNode(true);
          this.itemsNode.appendChild(itemNode);

          const titleNode = itemNode.querySelector(
            ".serve__items__item__image__title"
          );
          titleNode.textContent = item.image.title;

          const textNode = itemNode.querySelector(".serve__items__item__text");
          textNode.textContent = item.text;

          const imageNode = itemNode.querySelector(".image");
          await new CustomImage(imageNode, item.image.image).onReady();
        })
      );

      resolve();
    });
  };

  onReady = () => {
    return new Promise(async (resolve, reject) => {
      this.mounted = true;

      await this.setupItems();

      this.carousel = new Carousel(this.carouselNode);

      resolve();
    });
  };

  onComplete = () => {
    this.onResize();

    this.scroll = getScroll();
    this.scroll.registerOnScrollPositionChange(this.onScrollPositionChange);
  };

  onResize = () => {
    this.bounds = this.block.getBoundingClientRect();
    this.top = this.block.offsetTop;
    this.windowSizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  };
}
