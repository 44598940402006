import "./style.css";
import CustomImage from "@blocks/Image";
import { getScroll } from "@app";
import { clamp, map } from "@utils/math";

export default class Raccolta {
  static selector = ".raccolta";

  constructor(block) {
    this.block = block;
    this.itemsNode = block.querySelector(".raccolta__items");
    this.itemTemplate = block.querySelector("#raccolta-item-template");

    this.items = [
      {
        image: {
          image: {
            src: "https://i.imgur.com/oKm5J2Z.jpg",
            alt: "",
          },
        },

        number: "01",

        text: "Le foglie di tabacco vengono raccolte rigorosamente manualmente una volta raggiunto il livello ottimale di maturazione, per rendere il nostro gin davvero unico e speciale.",
      },

      {
        image: {
          small: true,
          image: {
            src: "https://i.imgur.com/ORHAPuy.jpg",
            alt: "",
          },
        },

        number: "02",

        text: "Dopo la raccolta, tutte le foglie vengono controllate e manipolate con cura per non comprometterne la tenuta.\r\n\r\nPer un gin di qualità c’è bisogno di un prodotto di estrema qualità.",
      },
    ];
  }

  onScrollPositionChange = (event) => {
    const { animatedScroll } = event;
    const { height } = this.bounds;
    const { height: wHeight } = this.windowSizes;
    const y = animatedScroll + wHeight;

    const progress = clamp(
      map(y, this.top, this.top + height + wHeight, -1, 1),
      -1,
      1
    );

    //this.imageDecoratorNode.style.transform = `rotate(${180 * progress}deg)`;
  };

  setupItems = () => {
    return new Promise(async (resolve) => {
      await Promise.all(
        this.items.map(async (item) => {
          const itemNode =
            this.itemTemplate.content.firstElementChild.cloneNode(true);
          this.itemsNode.appendChild(itemNode);

          const numberNode = itemNode.querySelector(
            ".raccolta__items__item__text__number"
          );
          numberNode.textContent = item.number;

          const textNode = itemNode.querySelector(
            ".raccolta__items__item__text__text"
          );
          textNode.innerText = item.text;

          const imageWrapperNode = itemNode.querySelector(
            ".raccolta__items__item__image"
          );

          imageWrapperNode.classList.toggle(
            "raccolta__items__item__image--small",
            item.image.small === true
          );

          const imageNode = itemNode.querySelector(".image");
          await new CustomImage(imageNode, item.image.image).onReady();
        })
      );

      resolve();
    });
  };

  onReady = () => {
    return new Promise(async (resolve, reject) => {
      this.mounted = true;

      await this.setupItems();

      resolve();
    });
  };

  onComplete = () => {
    this.onResize();

    this.scroll = getScroll();
    this.scroll.registerOnScrollPositionChange(this.onScrollPositionChange);
  };

  onResize = () => {
    this.bounds = this.block.getBoundingClientRect();
    this.top = this.block.offsetTop;
    this.windowSizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  };
}
