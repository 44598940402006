import "./style.css";
//import layout from "./layout.html";
import CustomImage from "@blocks/Image";
import { getScroll } from "@app";
import { clamp, map } from "@utils/math";

export default class Provalo {
  static selector = ".provalo";

  constructor(block) {
    this.block = block;
    //this.block.innerHTML = layout;
    this.formNode = this.block.querySelector(".provalo__form");
    this.formNode.addEventListener("submit", this.onFormSubmit);

    this.feedbackNode = block.querySelector(".provalo__feedback");

    this.firstNameField = this.formNode
      .querySelector("input[name=firstName]")
      ?.closest(".provalo__form__fields__field");
    this.firstNameField.addEventListener("focusin", this.onFormFieldFocusin);

    this.lastNameField = this.formNode
      .querySelector("input[name=lastName]")
      ?.closest(".provalo__form__fields__field");
    this.lastNameField.addEventListener("focusin", this.onFormFieldFocusin);

    this.activityNameField = this.formNode
      .querySelector("input[name=activityName]")
      ?.closest(".provalo__form__fields__field");
    this.activityNameField.addEventListener("focusin", this.onFormFieldFocusin);

    this.messageField = this.formNode
      .querySelector("input[name=message]")
      ?.closest(".provalo__form__fields__field");
    this.messageField.addEventListener("focusin", this.onFormFieldFocusin);

    this.emailField = this.formNode
      .querySelector("input[name=email]")
      ?.closest(".provalo__form__fields__field");
    this.emailField.addEventListener("focusin", this.onFormFieldFocusin);

    this.privacyPolicyField = this.formNode
      .querySelector("input[name=privacyPolicy]")
      ?.closest(".provalo__form__fields__field");
    this.privacyPolicyField.addEventListener(
      "focusin",
      this.onFormFieldFocusin
    );

    this.newsField = this.formNode
      .querySelector("input[name=news]")
      ?.closest(".provalo__form__fields__field");
  }

  showFeedback = () => {
    this.feedbackNode.classList.add("provalo__feedback--visible");

    setTimeout(() => {
      this.feedbackNode.classList.remove("provalo__feedback--visible");
      this.formNode.reset();
    }, 3000);
  };

  onFormFieldFocusin = (event) => {
    const target = event.currentTarget;
    target.classList.remove("form__field--wrong");
  };

  onFormSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData(this.formNode);
    const firstName = formData.get("firstName");
    const lastName = formData.get("lastName");
    const message = formData.get("message");
    const activityName = formData.get("activityName");
    const email = formData.get("email");
    const privacyPolicy = formData.get("privacyPolicy");
    const news = formData.get("news");

    // validate form data
    if (this.firstNameField && !firstName) {
      this.firstNameField.classList.add("form__field--wrong");
    }
    if (this.lastNameField && !lastName) {
      this.lastNameField.classList.add("form__field--wrong");
    }
    if (this.activityNameField && !activityName) {
      this.activityNameField.classList.add("form__field--wrong");
    }
    if (this.messageField && !message) {
      this.messageField.classList.add("form__field--wrong");
    }
    if (
      (this.emailField && !email) ||
      this.emailField?.querySelector("input")?.validity?.typeMismatch
    ) {
      this.emailField.classList.add("form__field--wrong");
    }

    if (this.privacyPolicyField && !privacyPolicy) {
      //this.setErrorMessage("Please accept the privacy policy to subscribe.");
      this.privacyPolicyField.classList.add("form__field--wrong");
      return;
    }

    if (!firstName || !lastName || !email) {
      //this.setErrorMessage("Please complete all fields to subscribe.");
      return;
    }

    if (this.emailField?.querySelector("input")?.validity?.typeMismatch) {
      //this.setErrorMessage("Please enter a valid email address.");
      return;
    }

    console.log("NewsletterModal onFormSubmit", {
      firstName,
      lastName,
      message,
      activityName,
      email,
      privacyPolicy,
      news,
    });

    const options = {
      method: "POST",
      headers: {
        accept: "application/json",
        "content-type": "application/json",
        Authorization:
          "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiYTI1MGY4OGRkZDA3YWUzNTM1NzJhNzlmYWFjN2IxY2ZkNzBlMjRkMDkzOGZjYWVjMGJmZjg2Zjg4ZDhlNjc0MmY3MmRiM2M0NzY3OGE2MWIiLCJpYXQiOjE3MTU2OTIxMDEuODgxNjQxLCJuYmYiOjE3MTU2OTIxMDEuODgxNjQzLCJleHAiOjQ4NzEzNjU3MDEuODc3NTA4LCJzdWIiOiI5MTUzNDUiLCJzY29wZXMiOltdfQ.rpYJpIF_3Dd5Lro234pgZ9eH69QX34z0DzMB3onKk08htaKasfv7pgaSBDcz0GcEEMiEb29GYlXObs5v8dM7md7PWpsYPABaVPebGwyzDoMYv7MzB_OaGkk9oPyYH1FD3xPLVWv0suSDlIc-tMhNbrQmVncvFQpSqv3SS9lPN5Y06Cu2VaqeUgnSS4gQfB9-3Z5hkcTnwY-3-Np2hJI8K7FD_Gp-uSznhEVWZXNgHn_o3wZrJGJKa5gmD23yEsmyQXItoDNKDOV0vhaHeKpqDjjjbWWeo_5nlJWMhTxQ1F0YBXATwNUnkhXLD0vMmPTgi4aiqhD6kOkKvYaWOJyng9fIXa52TUXgODvDQMAKgKuN7T96wKs1ElF6Urj8NugJufDaa9nHJYpyjjof66HQj5iwc3AihyUKyrUQz1mRfVNXo1tI5EWBmtnbSCfiTGC1jrZ6E6ew9mBBDeFjIaOME2qM5yqNa1dw5Ct_T6P0TRSJ5N_dwPJW-dz2jxlAWVVJcukQ5tZOG13591NObvftPY4QRl5f0UO1HWgWBDzlzn6zZR4g7w__NaRSUuBr1fQt-6NCSKeVM9tgQDQArSIsco8rwplrflqyYQXHiySXzdZ_OsvyrUb5hsyQyNYONZ1ACQh8FfmPEFvmQ8xe4ww-3aDCZfQddxcv9-4zmc_mvcI",
      },
      body: JSON.stringify({
        fields: {
          name: firstName,
          last_name: lastName,
          message: message,
          company: activityName,
          news_offers_and_updates: news,
        },
        email: email,
        groups: ["118319525044160461"],
      }),
    };

    fetch("https://connect.mailerlite.com/api/subscribers", options)
      .then((response) => response.json())
      .then((response) => {
        this.showFeedback();
        console.log(response);
      })
      .catch((err) => console.error(err));
  };

  onScrollPositionChange = (event) => {
    const { animatedScroll } = event;
    const { height } = this.bounds;
    const { height: wHeight } = this.windowSizes;
    const y = animatedScroll + wHeight;

    const progress = clamp(
      map(y, this.top, this.top + height + wHeight, -1, 1),
      -1,
      1
    );

    //this.imageDecoratorNode.style.transform = `rotate(${180 * progress}deg)`;
  };

  onReady = () => {
    return new Promise(async (resolve, reject) => {
      this.mounted = true;

      resolve();
    });
  };

  onComplete = () => {
    this.onResize();

    this.scroll = getScroll();
    this.scroll.registerOnScrollPositionChange(this.onScrollPositionChange);
  };

  onResize = () => {
    this.bounds = this.block.getBoundingClientRect();
    this.top = this.block.offsetTop;
    this.windowSizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  };
}
