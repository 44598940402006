import "./style.css";
import Marquee from "@blocks/Marquee";
import { getScroll } from "@app";
import { clamp } from "@utils/math";

export default class Product {
  static selector = ".product";

  constructor(block) {
    this.block = block;
    this.marqueeNode = this.block.querySelector(".product__marquee");
  }

  onScrollPositionChange = (event) => {
    const { animatedScroll } = event;
    const { height } = this.bounds;

    let progress = animatedScroll / height;
    progress = clamp(progress, 0, 1);

    //this.marqueeNode.style.transform = `rotate(-9.4deg) translateX(calc(var(--marquee-translate-value) * ${-progress}))`;
  };

  onReady = () => {
    return new Promise(async (resolve, reject) => {
      this.mounted = true;

      this.marquee = await new Marquee(
        this.marqueeNode,
        "&nbsp;premium tobacco gin - the gin that doesn't taste like gin - don't smoke it, drink it -"
      ).onReady();

      resolve();
    });
  };

  onComplete = () => {
    return new Promise(async (resolve, reject) => {
      this.onResize();

      this.scroll = getScroll();
      this.scroll.registerOnScrollPositionChange(this.onScrollPositionChange);

      resolve();
    });
  };

  onResize = () => {
    this.bounds = this.block.getBoundingClientRect();
  };
}
