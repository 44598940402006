import "./style.css";

import Marquee from "@blocks/Marquee";

import { getScroll } from "@app";
import { clamp } from "@utils/math";

export default class Splash {
  static selector = ".splash";

  constructor(block) {
    this.block = block;
    this.marqueeNode = this.block.querySelector(".splash__marquee");
  }

  onReady = () => {
    return new Promise(async (resolve, reject) => {
      this.mounted = true;

      this.marquee = new Marquee(
        this.marqueeNode,
        "&nbsp;premium tobacco gin - the gin that doesn't taste like gin - don't smoke it, drink it -"
      );
      await this.marquee.onReady();

      resolve();
    });
  };

  onComplete = () => {
    return new Promise(async (resolve, reject) => {
      this.onResize();

      resolve();
    });
  };

  onResize = () => {};
}
