import "./style.css";
import CustomImage from "@blocks/Image";
import { getScroll } from "@app";
import { clamp, map } from "@utils/math";

export default class Tabacco {
  static selector = ".tabacco";

  constructor(block) {
    this.block = block;
    this.imagesNode = block.querySelector(".tabacco__images");
    this.imageTemplate = block.querySelector("#tabacco-image-template");

    this.images = [
      {
        src: "https://i.imgur.com/NcK6xpb.jpg",
        alt: "",
      },
      {
        src: "https://i.imgur.com/4l9CkIt.jpg",
        alt: "",
      },
      {
        src: "https://i.imgur.com/mpjKIbL.jpg",
        alt: "",
      },
      {
        src: "https://i.imgur.com/Evj7sQ8.jpg",
        alt: "",
      },
      {
        src: "https://i.imgur.com/HS7RodV.jpg",
        alt: "",
      },
    ];
  }

  onScrollPositionChange = (event) => {
    const { animatedScroll } = event;
    const { height } = this.bounds;
    const { height: wHeight } = this.windowSizes;
    const y = animatedScroll + wHeight;

    const progress = clamp(
      map(y, this.top, this.top + height + wHeight, -1, 1),
      -1,
      1
    );

    //this.imageDecoratorNode.style.transform = `rotate(${180 * progress}deg)`;
  };

  setupImages = () => {
    return new Promise(async (resolve) => {
      await Promise.all(
        this.images.map(async (image) => {
          const imageNode =
            this.imageTemplate.content.firstElementChild.cloneNode(true);
          this.imagesNode.appendChild(imageNode);

          await new CustomImage(
            imageNode.querySelector(".image"),
            image
          ).onReady();
        })
      );

      resolve();
    });
  };

  onReady = () => {
    return new Promise(async (resolve, reject) => {
      this.mounted = true;

      await this.setupImages();

      resolve();
    });
  };

  onComplete = () => {
    this.onResize();

    this.scroll = getScroll();
    this.scroll.registerOnScrollPositionChange(this.onScrollPositionChange);
  };

  onResize = () => {
    this.bounds = this.block.getBoundingClientRect();
    this.top = this.block.offsetTop;
    this.windowSizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  };
}
