import "./style.css";
import { clamp, map } from "@utils/math.js";
import { easeInOutExpo } from "@utils/easing.js";
import { getRaf } from "@app";

const MSDF_ATLASES_TO_LOAD = [];

export default class Loader {
  static selector = ".loader";

  constructor(block) {
    this.block = block;

    this.resourcesToLoad = 0;
    this.resourcesLoaded = 0;
    this.reasourcesProgress0 = 0;
    this.reasourcesProgress = 0;

    this.preloadedResources = {};

    this.animation = {
      duration: 200,
      ease: easeInOutExpo,
      fromValue: 0,
      toValue: 100,
      current: 0,
      setValue: (progress) => {
        this.animation.current = clamp(map(progress, 0, 0.999, 0, 1), 0, 1);
      },
    };
  }

  loadFontFaces = async () => {
    await document.fonts.ready;
  };

  loadImages = () => {
    this.imagesToLoad = document.querySelectorAll("img[src]:not([src=''])");
    this.resourcesToLoad += this.imagesToLoad.length;

    if (this.resourcesToLoad === 0) {
      this.reasourcesProgress = 1;
      return;
    }

    const loadImagePromises = [];

    this.imagesToLoad.forEach((imageToLoad) => {
      loadImagePromises.push(
        new Promise((resolve) => {
          const onImageLoaded = () => {
            this.resourcesLoaded += 1;

            this.reasourcesProgress =
              this.resourcesLoaded / this.resourcesToLoad;

            this.preloadedResources[imageToLoad.src] = imageToLoad;

            // console.log(
            //   "%c Image ",
            //   "background: yellow; color: black",
            //   "loaded"
            // );

            resolve();
          };

          if (imageToLoad.complete) {
            onImageLoaded();
          } else {
            imageToLoad.addEventListener("load", onImageLoaded);
          }
        })
      );
    });

    return Promise.all(loadImagePromises);
  };

  load = () => {
    let transitionPromise = {};
    transitionPromise.promise = new Promise(
      (resolve) => (transitionPromise.resolve = resolve)
    );
    this.raf = getRaf();
    this.raf.register("loader", (timestamp) => {
      this.render(timestamp, transitionPromise);
    });

    const loadImagesPromise = this.loadImages();

    return Promise.all([loadImagesPromise, transitionPromise.promise]);
  };

  hide = () => {
    return new Promise(async (resolve) => {
      setTimeout(async () => {
        this.block.classList.add("loader--hidden");
        document.body.classList.add("loaded");
        this.block.style.pointerEvents = "none";
        this.block.classList.add("loader--loaded");

        resolve();
      }, 480);
    });
  };

  handleTransitionEnd = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        this.block.style.pointerEvents = "none";
        this.block.classList.add("loader--loaded");

        resolve();
      }, 1100);
    });
  };

  animate = () => {
    const textProgress = Math.round(
      this.animation.toValue * this.animation.current
    );

    this.textNodeContent.textContent = `${0 + textProgress}`;
  };

  render = async (timestamp, promise) => {
    this.progress = this.reasourcesProgress;

    if (this.progress === 1) {
      this.raf.unregister("loader");
      promise.resolve();
    }
  };
}
