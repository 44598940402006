import "./style.css";
import CustomImage from "@blocks/Image";
import { getScroll } from "@app";
import { clamp, map } from "@utils/math";

export default class Oro {
  static selector = ".oro";

  constructor(block) {
    this.block = block;
    this.imageTemplate = block.querySelector("#oro-image-template");
    this.imagesNode = block.querySelector(".oro__images");

    this.images = [
      {
        src: "https://i.imgur.com/qQRlear.jpg",
        alt: "",
      },
      {
        src: "https://i.imgur.com/duuy4sW.jpg",
        alt: "",
      },
      {
        src: "https://i.imgur.com/rlbLeFz.jpg",
        alt: "",
      },
      {
        src: "https://i.imgur.com/Da9aQnK.jpg",
        alt: "",
      },
      {
        src: "https://i.imgur.com/tz7j18t.jpg",
        alt: "",
      },
      {
        src: "https://i.imgur.com/gb7HUL4.jpg",
        alt: "",
      },
      {
        src: "https://i.imgur.com/9U4SOLo.jpg",
        alt: "",
      },
      {
        src: "https://i.imgur.com/7FOyHfU.jpg",
        alt: "",
      },
    ];
  }

  onScrollPositionChange = (event) => {
    const { animatedScroll } = event;
    const { height } = this.bounds;
    const { height: wHeight } = this.windowSizes;
    const y = animatedScroll + wHeight;

    const progress = clamp(
      map(y, this.top, this.top + height + wHeight, -1, 1),
      -1,
      1
    );

    this.imagesNode.style.transform = `translateX(${-200 * progress}px)`;
  };

  setupImages = () => {
    return new Promise(async (resolve) => {
      await Promise.all(
        this.images.map(async (image) => {
          const imageNode =
            this.imageTemplate.content.firstElementChild.cloneNode(true);
          this.imagesNode.appendChild(imageNode);

          await new CustomImage(imageNode, image).onReady();
        })
      );

      resolve();
    });
  };

  onReady = () => {
    return new Promise(async (resolve, reject) => {
      this.mounted = true;

      await this.setupImages();

      resolve();
    });
  };

  onComplete = () => {
    this.onResize();

    this.scroll = getScroll();
    this.scroll.registerOnScrollPositionChange(this.onScrollPositionChange);
  };

  onResize = () => {
    this.bounds = this.block.getBoundingClientRect();
    this.top = this.block.offsetTop;
    this.windowSizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  };
}
